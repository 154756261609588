import React, {useLayoutEffect} from 'react';
import {isLoggedIn} from "@js/services/auth";
import {Link, navigate} from "gatsby";
import {Helmet} from "react-helmet";
import AdvisorTopBar from "@js/component/AdvisorTopBar";
import Heading from "@js/component/Heading";
import ChevronRightIcon from "@js/component/icon/ChevronRightIcon";
import Box from "@js/component/Box";
import AdvisorMenu from "@js/component/AdvisorMenu";
import MyWorkMapsView from "@js/component/MyWorkMapsView";
import Main from "@js/component/Main";

import * as classNames from "@css/component/template/portal/MyProducts.module.scss";

/**
 * @const MyWorkAreas
 */
const MyWorkAreas = () => {
    useLayoutEffect(() => {
        if (!(isLoggedIn())) {
            navigate("/portaal/login", { replace: true });
        }
    }, []);

    return (
        <Main>
            <Helmet>
                <title>Mijn werkgebieden</title>
            </Helmet>
            <AdvisorTopBar />
            <div className={ classNames.container }>
                <section className={ classNames.myProductsPage }>
                    <header className={ classNames.header }>
                        <Heading element="h1" variant="large">
                            Dashboard voor energielabel-adviseurs
                        </Heading>
                        <div className={ classNames.breadcrumbs }>
                            <Link className={ classNames.previous } to="/portaal/dashboard">Dashboard</Link>
                            <ChevronRightIcon className={ classNames.icon } />
                            <span className={ classNames.current }>Mijn producten</span>
                        </div>
                    </header>
                    <div className={ classNames.blocks }>
                        <div className={ classNames.blockOne }>
                            <Box>
                                <AdvisorMenu />
                            </Box>
                        </div>
                        <div className={ classNames.blockTwo }>
                            <Box>
                                <MyWorkMapsView />
                            </Box>
                        </div>
                    </div>
                </section>
            </div>
        </Main>
    );
}

export default MyWorkAreas;