import React, {useEffect, useState} from 'react';

import Heading from "@js/component/Heading";
import PlusCircleIcon from "@js/component/icon/PlusCircleIcon";

import * as classNames from "@css/component/MyWorkMapsView.module.scss";
import ChevronRightIcon from "@js/component/icon/ChevronRightIcon";
import {Link} from "gatsby";
import {useSelector} from "react-redux";
import StoreState from "@js/store/StoreState";
import WorkAreaVisibility from "@js/component/WorkAreaVisibility";

/**
 * @type WorkMap
 */
type WorkMap = {
    dailyCounter: number;
    dailyRequests: number;
    deliveryTime: number;
    isVisible: boolean;
    id: number;
    name: string;
    priceTiers: {
        area: string;
        buildingType: string;
        buildingTypeCategory: string;
        hash: string;
        id: number;
        methodology: string;
        price: string;
        requiresQuote: boolean;
        promotion: {
            id: number;
            label: string;
            uri: string;
            img: {
                src: string;
                alt: string;
            }
        }
    };
    workAreas: {
        endZipcode: number;
        id: number;
        startZipcode: number;
    }
};

const MyWorkMapsView = () => {
    const defaultWorkMaps: WorkMap[] = [];

    const [workMaps, setWorkMaps] = useState(defaultWorkMaps);

    const token = useSelector((storeState: StoreState) => storeState.security.token);

    const getMyData = async () => {
        const response = await fetch(`${process.env.GATSBY_API_ADVISOR_URL}/workmaps?token=${token}`, {
            method: "GET"
        });

        const data = await response.json();

        const newWorkMaps = [ ...workMaps ];

        data.map((workMap: WorkMap, index: number) => {
            newWorkMaps[index] = workMap;
        });

        setWorkMaps(newWorkMaps);
    };

    const setWorkAreaVisibility = async (workMapId, isVisible) => {
        const visibility = isVisible ? false : true;

        const response = await fetch(`${ process.env.GATSBY_API_ADVISOR_URL }/workmap/${workMapId}/state?token=${token}`, {
            body: JSON.stringify({
                isVisible: visibility
            }),
            method: "PUT"
        });

        const data = await response.json();

        if(data.status == 'succeeded') {
            getMyData();
        }
    }

    useEffect(() => {
        getMyData();
    }, []);

    return (
        <div className={ classNames.myWorkMapsView }>
            <div className={ classNames.container }>
                <Heading className={ classNames.heading } element="h1" variant="medium">
                    Mijn producten
                    <div className={ classNames.addWorkmap }>
                        <PlusCircleIcon className={ classNames.icon } />
                        <Link
                            to="/portaal/nieuw-werkgebied"
                        >
                            <label className={ classNames.label }>Nieuw werkgebied</label>
                        </Link>
                    </div>
                </Heading>
                <p className={ classNames.paragraph }>
                    Op deze pagina van het dashboard bepaal je in welke werkgebieden je jouw bedrijf beschikbaar stelt voor
                    het ontvangen van opdrachten via Woninglabel.nl. Door het beheren van verschillende werkgebieden kan
                    je verschillende combinaties aan voorwaarden voor het opstellen van energielabels aanbieden.
                    Zo kan je door twee keer een zelfde werkgebied aan te maken verschillende producten voor
                    verschillende levertijden en beschikbaarheid aanbieden. Het bied je ook de mogelijkheid om een
                    zelfde product dichtbij huis voor andere tarieven, levertijden en beschikbaarheid aan te bieden dan verder weg.
                    Uiterst links in de voorvertoning van het werkgebied staat een oogje weergegeven. Is deze open en groen?
                    Dan is het werkgebied actief. Is deze gesloten en rood? Dan is het werkgebied inactief.
                    Door op het oogje te klikken activeer en deactiveer je het werkgebied.
                    Voeg een werkgebied toe door rechts bovenin te klikken op ‘Nieuw werkgebied’ of klik op een
                    bestaand werkgebied om deze te bewerken.
                </p>
                <div className={ classNames.table }>
                    <div className={ classNames.tableHeader }>
                        <Heading element="h3" variant="small">Werkgebied</Heading>
                        <Heading element="h3" variant="small">Levertijd</Heading>
                        <Heading element="h3" variant="small">Beschikbaarheid</Heading>
                    </div>
                    {workMaps.length > 0 && workMaps.map((item) =>
                        <div
                            key={item.id}
                            className={ classNames.tableData }
                        >
                            <span>
                                <WorkAreaVisibility
                                    isVisible={ item.isVisible }
                                    onVisibilityClick={ () => setWorkAreaVisibility(item.id, item.isVisible) }
                                />
                            </span>
                            <span>
                                <Link
                                    key={item.id}
                                    to={`/portaal/mijn-product/${ item.id }`}
                                >{item.name}
                                </Link>
                            </span>
                            <span>{item.deliveryTime} werkdagen</span>
                            <span>{item.dailyRequests} per dag</span>
                            <span>
                                <Link
                                    key={item.id}
                                    to={`/portaal/mijn-product/${ item.id }`}
                                >
                                    <ChevronRightIcon className={ classNames.icon }/>
                                </Link>
                            </span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default MyWorkMapsView;